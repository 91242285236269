import Vivus from 'vivus';

document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--solutions');
	const config = { rootMargin: '0px 0px',	threshold: 0 };

	const block_solutions = {

		init: (block) => {
			
			let filters = block.querySelectorAll('.filter');
			filters.forEach( filter => {
				block_solutions.filters(filter);
			})
			
			let arrow = block.querySelector('.arrow');
			if (arrow) { block_solutions.vivus(arrow); }

			block_solutions.solutions(block);
			block_solutions.filter(block);
			block_solutions.reset(block);
		},
		
		blockIntersection: (entries) => {
			
			entries.forEach( entry => {
				if ( entry.intersectionRatio > 0 ) {
					blockObserver.unobserve( entry.target );
					// block_solutions.animate( entry.target );
				}
			});		
		},

		filters: (filter) => {

			let button = filter.querySelector('.dropdown-toggle');
			let input = filter.querySelector('input');
			let options = filter.querySelectorAll('.dropdown-item');

			options.forEach( option => {
				block_solutions.listener(button, option, input);
			})

		},

		listener: (button, option, input) => {

			let text = option.innerText.toLowerCase();

			option.addEventListener('click', function() {
				button.innerText = text;
				input.value = option.dataset.filter;
			}, false );
		},

		filter: (block) => {

			let submit = block.querySelector('.filter-submit');

			submit?.addEventListener('click', function(e) {
				e.preventDefault();
				block_solutions.solutions(block);
			})
		},
 
		solutions: (block) => {

			let spinner = block.querySelector('.spinner');
			let form = block.querySelector('.solutions-filters form');
			let target = block.querySelector('.solutions-target');
			let posts = target.dataset.posts;

			target.innerHTML = '';
			spinner.style.display = 'block';

			const data = (form)? new FormData(form) : new FormData();

			data.append('action', 'block_solutions_get');
			data.append('nonce', block_solutions_ajax.nonce);
			data.append('url', window.location.href);
			data.append('posts', posts);
			
			fetch ( block_solutions_ajax.ajax_url, {
				method: 'POST',
				credentials: 'same-origin',
				body: data
			})

			.then( (response) => {

				if (!response.ok) {
					block_solutions.log('Error getting solutions');
					return;
				}
				return response;
			})
			
			.then( (response) => response.text() )
			
			.then( (data) => {
				spinner.style.display = 'none';
				target.innerHTML = data;
			})

		},

		reset: (block) => {

			let filters = block.querySelectorAll('.filter');

			block.addEventListener('click', function(e){
				if ( e.target.classList.contains('clear-filters') ) {
					filters.forEach( filter => {
						filter.querySelector('.dropdown-toggle').innerHTML = '<wbr>';
						filter.querySelector('input').value = '';
					})

					block_solutions.solutions(block);
				}
			})
		},

		vivus: (icon) => {

			let vivus = new Vivus(icon, {
				type: 'oneByOne',
				duration: 100,
				pathTimingFunction: Vivus.EASE_IN,
				onReady: function() {
					icon.style.opacity = 1;
				}
			});
		},
		
		animate: (block) => {
			block.classList.add('animate');
		},
		
		log: (message) => {
			console.log(message);
		}
	}

	let blockObserver = new IntersectionObserver( block_solutions.blockIntersection, config );

	blocks.forEach( block => {
		block_solutions.init(block);
		blockObserver.observe( block );
	});
});